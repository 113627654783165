// IMPORTANT: with every content update, validate all tactic titles with CheckSolveMyProblemContentPage
const SolveMyProblemContent = {
  title: 'I want to...',
  deckOptions: [
    {
      deckLink: 'Work more efficiently',
      deckFocusPrompt: 'More specifically, I want to...',
      deckId: '8',
      focusOptions: [
        {
          title: 'Set clear and realistic goals',
          cards: [
            'SMART goals',
            'Ikigai',
            'Communication Canvas',
            'Canary In the Coalmine',
          ],
        },
        {
          title: 'Prioritise ruthlessly',
          cards: [
            'Single Step',
            'Seinfeld System',
            'Eisenhower Matrix',
            'Decision Dash',
          ],
        },
        {
          title: 'Build positive habits',
          cards: [
            'Ritual Recalibration',
            'Sunrise Scribblings',
            'Wonder Beam',
            'Power Hours',
          ],
        },
        {
          title: 'Manage my time',
          cards: [
            'Time Blocking',
            'Kill It With Fire',
            'Pomodoro',
            'Meeting Mincer',
          ],
        },
        {
          title: 'Minimise my work in progress',
          cards: [
            'Kanban',
            'One In, One Out',
            'Two-Minute Taskmaster',
            'Progress Check',
          ],
        },
        {
          title: 'Reduce multitasking',
          cards: [
            'Single-Task Superhero',
            '3\u20133\u20133',
            'Mindful Mojo',
            'Time Blocking',
          ],
        },
        {
          title: 'Eliminate distractions',
          cards: [
            'Focus Fortress',
            'Open Loops',
            'Yes, Maybe, BTW',
            'Out Of Order',
          ],
        },
        {
          title: 'Make time for breaks and rest',
          cards: [
            'Daydream Station',
            'Time Oasis',
            'Mindful Disruption',
            'Laugh Out Loud',
          ],
        },
        {
          title: 'Celebrate milestones',
          cards: [
            'Winventory',
            'Tiny Rewards',
            'Weekly Focus',
            'Productive Procrastination',
          ],
        },
        {
          title: 'Never stop learning',
          cards: ['Spark File', 'Get Feedback', 'Hard Truths', 'Weeknotes'],
        },
        {
          title: 'Look after myself',
          cards: [
            'Wellbeing 101',
            'One-Minute Breathing',
            'Monk Mode',
            'Nimble Eyes, Nimble Mind',
          ],
        },
        {
          title: 'Self reflect',
          cards: [
            'Energy Audit',
            'Synapse Safari',
            'S.T.O.P',
            'Captain Hindsight',
          ],
        },
      ],
    },
    {
      deckLink: 'Create a stand-out brand',
      deckFocusPrompt: 'More specifically, I want to...',
      deckId: '7',
      focusOptions: [
        {
          title: 'Find our uniqueness',
          cards: [
            'Category Clich\u00e9s',
            'The True Competitor',
            'No-Go Zones',
            'Weird Or Normal?',
          ],
        },
        {
          title: 'Identify our strengths',
          cards: [
            'Only Is Better Than Best',
            'The Dolly Score',
            'So You Think You\u2019re Special?',
            'Value Mining',
          ],
        },
        {
          title: 'Identify our weaknesses',
          cards: [
            'Cultivate A Weakness',
            'We Suck!',
            'The Challenger Trap',
            'Hamster Wheel',
          ],
        },
        {
          title: 'Understand the customer better',
          cards: [
            'Superfan Secrets',
            'The Targeting Paradox',
            'Look at Me!',
            'The Real Product',
          ],
        },
        {
          title: 'Know what people are thinking',
          cards: [
            'Word On The Street',
            'The Interesting Stranger',
            'The Silent Market',
            'So You Think You\u2019re Special?',
          ],
        },
        {
          title: 'Refine our offering',
          cards: [
            'Kill List',
            'Minimum Viable Strategy',
            'Paper Boss',
            'Find The Fear',
          ],
        },
        {
          title: 'Build differentiation',
          cards: [
            'Contrarian Value',
            'One Stupid Thing',
            'Invisible Rules',
            'The Outsider',
          ],
        },
        {
          title: 'Validate ideas',
          cards: [
            'First Goal of Strategy',
            'How Cascade',
            'Clear The Pipes',
            'Simple. Easy. Fun.',
          ],
        },
        {
          title: 'Organise ideas',
          cards: [
            'The Strategic Hierarchy',
            'What\u2019s The Gist?',
            'Pub Language',
            'Ditch The Deck',
          ],
        },
        {
          title: 'Inspire (and include) the team',
          cards: [
            'Strategy Story',
            'The \u2018Why\u2019',
            'Opposites Game',
            'Chief Value Officer',
          ],
        },
        {
          title: 'Write our narrative',
          cards: [
            'Power Platform',
            'The Philosopher',
            'Subjectivity Test',
            'Copy Playbook',
          ],
        },
        {
          title: 'Get creative ideas',
          cards: [
            'The Creative Canvas',
            'Between You & Me',
            'Idea Fishing',
            'Cross-Category Copying',
          ],
        },
        {
          title: 'Grow and expand further',
          cards: [
            'Brand-Led Growth',
            'Brand Stretch',
            'Kill List',
            'Paper Boss',
          ],
        },
      ],
    },
    {
      deckLink: 'Run engaging workshops',
      deckFocusPrompt: 'More specifically, I want to...',
      deckId: '1',
      focusOptions: [
        {
          title: 'Prepare in advance',
          cards: [
            'Meeting Wizard',
            'Workshop Checklist',
            'T-Bar Format',
            'Stakeholder Map',
          ],
        },
        {
          title: 'Build team connection',
          cards: [
            'Skills Market',
            'Mad, Sad, Glad',
            'Ice Breakers',
            'Fishbowl',
          ],
        },
        {
          title: 'Use our time wisely',
          cards: [
            'Parking Lot',
            'Theme Sort',
            'Session Principles',
            'Who, What, When',
          ],
        },
        {
          title: 'Encourage team responsibility',
          cards: [
            'Who, What, When',
            'Roles & Responsibilities',
            'Stakeholder Map',
            'Hopes & Fears',
          ],
        },
        {
          title: 'Frame our problem',
          cards: [
            'Sailboat',
            'Five Whys',
            'Problem Statement',
            'Newspaper Headline',
          ],
        },
        {
          title: 'Understand the project scope',
          cards: [
            'Assumption Collecting',
            'Empathy Map',
            'Prototype Persona',
            'Journey Map',
          ],
        },
        {
          title: 'Agree on our direction',
          cards: [
            'Secret Vote',
            'Objectives & Key Results',
            'G.R.O.W',
            'Democratic Discussion',
          ],
        },
        {
          title: 'Solve problems',
          cards: [
            'Value Proposition',
            'Sticky Steps',
            'Hypothesis Statement',
            'How Might We...',
          ],
        },
        {
          title: 'Gain a broader understanding',
          cards: [
            'Storyboard',
            'Service Map',
            'Reverse Brainstorm',
            'Premortem',
          ],
        },
        {
          title: 'Generate ideas',
          cards: ['How Might We...', 'Idea Eight', 'Mind Map', 'Fishbowl'],
        },
        {
          title: 'Evolve ideas',
          cards: [
            'Round Robin',
            'SWOT Analysis',
            'Idea Beetle',
            'Heart, Head, Hand',
          ],
        },
        {
          title: 'Prioritise ideas',
          cards: [
            'Priority Map',
            'Impact Effort Map',
            'Assumption Map',
            '$100',
          ],
        },
        {
          title: "Learn from what we've done",
          cards: [
            'Mad, Sad, Glad',
            'Start, Stop, Continue',
            'Rose, Thorn, Bud',
            'Three Little Pigs',
          ],
        },
      ],
    },
    {
      deckLink: 'Help my team succeed',
      deckFocusPrompt: 'More specifically, I want to...',
      deckId: '6',
      focusOptions: [
        {
          title: 'Align on a common goal',
          cards: ['Team Vision', 'Start With Why', 'Team Charter', 'Top Tasks'],
        },
        {
          title: 'Choose guiding principles',
          cards: [
            'Team Values',
            'Design Principles',
            'Theme Sort',
            'Research Questions',
          ],
        },
        {
          title: 'Make decisions together',
          cards: [
            'Impact Effort Map',
            'Force-Field Analysis',
            'Private Vote',
            'Decision Stack',
          ],
        },
        {
          title: 'Define who does what',
          cards: [
            'Who, What, When',
            'Roles & Responsibilities',
            'Team Strategy',
            'Sphere Of Influence',
          ],
        },
        {
          title: 'Track our progress',
          cards: [
            'Goal, Signal, Metric',
            'Objectives & Key Results',
            'Lean Updates',
            'Task Modelling',
          ],
        },
        {
          title: 'Get more done',
          cards: [
            'Maker Time',
            'Ritual Reset',
            'Accountability Dial',
            'Frame The Problem',
          ],
        },
        {
          title: 'Communicate effectively',
          cards: [
            'Communication Matrix',
            'Agile Comms',
            'Daily Sharing',
            'Write In Plain English',
          ],
        },
        {
          title: 'Work across disciplines',
          cards: [
            'Shift Left Lenses',
            'Team Circles',
            'Team Modelling',
            'Design Crits',
          ],
        },
        {
          title: 'Get to know each other',
          cards: [
            'My User Manual',
            'Community Of Practice',
            'One-To-One',
            'Get Buy-in',
          ],
        },
        {
          title: 'Celebrate our wins',
          cards: [
            'Team Appreciation',
            'Appreciation Playbook',
            'Retros',
            'Daily Sharing',
          ],
        },
        {
          title: 'Check our wellbeing',
          cards: [
            'Health Monitor',
            'Say What You Mean',
            'Inclusive Meeting Playbook',
            'Onboarding Retros',
          ],
        },
        {
          title: 'Improve team efficiency',
          cards: [
            'Lean Updates',
            'Lean Wardley Map',
            'Productivity Blueprint',
            'One-To-One',
          ],
        },
      ],
    },
    {
      deckLink: 'Make confident decisions',
      deckFocusPrompt: 'More specifically, I want to...',
      deckId: '5',
      focusOptions: [
        {
          title: 'Choose a higher purpose',
          cards: [
            'Plainly Put',
            'Close Up Shop',
            'Give Me A Reason',
            'Name Your Game',
          ],
        },
        {
          title: 'Decide what we want',
          cards: [
            'Feasible Futures',
            'Better Now',
            'Challenge Statement',
            'Give Me A Reason',
          ],
        },
        {
          title: 'See the big picture',
          cards: ['Human Network', 'Fog Of Work', 'Value Chain', 'Five Whats'],
        },
        {
          title: 'Focus on the details',
          cards: [
            'Mystery Machine',
            'Value Chain',
            'Buzzword Breakup',
            'Seeking Specifics',
          ],
        },
        {
          title: 'Know the user',
          cards: [
            'Promise Proposal',
            'Positive Influence',
            'Human Network',
            'Big Frog, Little Pond',
          ],
        },
        {
          title: 'Build a solid foundation',
          cards: [
            'Building Blocks',
            'Adjustable Attitudes',
            'Take Responsibility',
            'Be First Or Be Fast',
          ],
        },
        {
          title: 'Problem solve as a team',
          cards: [
            'Complaint Department',
            'Challenge Statement',
            'Backbrief',
            'Embrace The Change',
          ],
        },
        {
          title: 'Predict the future',
          cards: [
            'Default Disaster',
            'Tripwires',
            'Active Waiting',
            'Fast-Forward Futures',
          ],
        },
        {
          title: 'Know the market',
          cards: [
            'Open-Source Intelligence',
            'Market Evolution',
            'Market Perspectives',
            'Value Chain',
          ],
        },
        {
          title: 'Face the truth',
          cards: [
            'Take Responsibility',
            'Legacy Trap',
            'Big Frog, Little Pond',
            'Disruption Readiness',
          ],
        },
        {
          title: 'Put learning first',
          cards: [
            'Decision Diary',
            'Measure Twice',
            'Experiment Methods',
            'Open-Source Intelligence',
          ],
        },
        {
          title: 'Match decisions with needs',
          cards: [
            'Shift Gears',
            'Changing Ways',
            'Compete Different',
            'Building Bridges',
          ],
        },
        {
          title: 'Get the best outcome',
          cards: [
            'Why DIY?',
            'Vendor Disassembler',
            'Buyer\u2019s Remorse',
            'Open Approaches',
          ],
        },
      ],
    },
    {
      deckLink: 'Develop and test new ideas',
      deckFocusPrompt: 'More specifically, I want to...',
      deckId: '3',
      focusOptions: [
        {
          title: 'Identify what kind of developing is needed',
          cards: [
            'Chasm Crossing',
            'Real Needs',
            'Shaping Trios',
            'Seeds vs Soil',
          ],
        },
        {
          title: 'Uncover what matters to your audience',
          cards: [
            'Watering Holes',
            'Pain X-Ray',
            'Documentary Conversation',
            'Switch Timeline',
          ],
        },
        {
          title: 'Come up with surprising new ideas',
          cards: [
            'Snowmobiling',
            'Forced Mutation',
            'Radical Repurposing',
            'Stretch Prompts',
          ],
        },
        {
          title: 'Break down ideas to test',
          cards: [
            '10-Star Experience',
            'Force 25',
            'Diagnostic \u2018Shop Along\u2019',
            'Human Story Map',
          ],
        },
        {
          title: 'Understand the risks in your ideas',
          cards: [
            'Disconfirmation Bias',
            'Hard Test, Easy Life',
            'Solve For Distribution',
            'Time Machine',
          ],
        },
        {
          title: 'Prioritise features',
          cards: [
            'Multiverse Map',
            'Complexity Heatmap',
            'Language Market Fit',
            'Be the Algorithm',
          ],
        },
        {
          title: 'Decide on success metrics',
          cards: ['Worth It?', 'Pivot Triggers', 'Get It?', 'Want It Enough?'],
        },
        {
          title: 'Test your ideas',
          cards: [
            'Be The Algorithm',
            'Solution Aikido',
            'Walking Skeleton',
            'Box Clever',
          ],
        },
        {
          title: 'Rapidly prototype',
          cards: [
            'Punchy Prototypes',
            '‘Huh?’ Test',
            'Pitch Provocations',
            'Behavioural Probe',
          ],
        },
        {
          title: 'Talk with the right customers',
          cards: [
            'Recruitment Probe',
            'Screeners',
            'Incentives',
            'Observation',
          ],
        },
        {
          title: 'Get the most out of customer sessions',
          cards: [
            'User Session Outline',
            'Question Cheat Sheet',
            'Universal Translator',
            'Speedy Synthesis',
          ],
        },
        {
          title: 'Understand your customer insights',
          cards: [
            'Anatomy Of An Insight',
            'Four Forces',
            '2D Comparison',
            'CSI Pinboard',
          ],
        },
      ],
    },
    {
      deckLink: 'Negotiate with confidence',
      deckFocusPrompt: 'More specifically, I want to...',
      deckId: '11',
      focusOptions: [
        {
          title: 'Master ethical negotiations',
          cards: [
            'Golden Rules',
            'Negotiation No-Goes',
            'Ethical Negotiation',
            'Judgement-Free Zone',
          ],
        },
        {
          title: 'Understand how connection works',
          cards: [
            'Judgement-Free Zone',
            'Stairway Of Influence',
            'Body Talk',
            '7 Principles Of Influence',
          ],
        },
        {
          title: 'Eliminate biases',
          cards: [
            'Bias Hunting',
            'Cultural Exploration',
            'A Whole New World (View)',
            'Friends & Foes',
          ],
        },
        {
          title: 'Discover background information',
          cards: [
            'Context Clues',
            'Crank The Levers',
            'Stakeholder Network Map',
            'Open Questions',
          ],
        },
        {
          title: 'Find your leverage',
          cards: [
            'Concessions',
            'Zest of Compromise',
            'Casino Environment',
            'Send in the Experts',
          ],
        },
        {
          title: 'Prepare in advance',
          cards: ['Ahead of the Game', 'Say What?', 'Micro Objectives', 'ZOPA'],
        },
        {
          title: 'Learn conversational tricks',
          cards: [
            'SUCCESs',
            'Emotional Detachment',
            'Dynamic Silence',
            'Red Flags',
          ],
        },
        {
          title: 'Create a connection',
          cards: [
            'Horns Or Halo?',
            'Like & Be Liked',
            'Rapport Reps',
            'Fully Present',
          ],
        },
        {
          title: 'Deepen your connection',
          cards: [
            'Rapport Reps',
            'Trust Tree',
            'Affirmative Attention',
            'Open Questions',
          ],
        },
        {
          title: 'Handle high emotions',
          cards: [
            'Emotional Detachment',
            'Ride The De-Escalator',
            'Tear Down The Barriers',
            'Empathy Amplifier',
          ],
        },
        {
          title: 'Get the converstaion back on track',
          cards: [
            'Pitfalls',
            'Tear Down The Barriers',
            'Indicators Of Progress',
            'Four-Part Apology',
          ],
        },
        {
          title: 'Fix a failing negotiation ',
          cards: [
            'Stalemate',
            'Fallback Plan',
            'Friends In High Places',
            'Ultimatum',
          ],
        },
        {
          title: 'Get the best outcome',
          cards: [
            'Drop the Anchor',
            'Crunch Time',
            'Stand & Deliver',
            'Ultimatum',
          ],
        },
      ],
    },
    {
      deckLink: 'Tell a compelling story',
      deckFocusPrompt: 'More specifically, I want to...',
      deckId: '2',
      focusOptions: [
        {
          title: 'Get to the point, fast',
          cards: [
            'What\u2019s It About?',
            'Emotional Dashboard',
            'Cut To The Chase',
            'Five Ts',
          ],
        },
        {
          title: 'Frame my work as a story',
          cards: [
            'The Dragon & The City',
            'Man In A Hole',
            'Order & Chaos',
            'Movie Time',
          ],
        },
        {
          title: 'Be noticed and remembered',
          cards: [
            'Story Hooks',
            'Secrets & Puzzles',
            'Leave It Out!',
            'Rules, Cheats & Rebels',
          ],
        },
        {
          title: "Learn from other people's stories",
          cards: [
            'Story-ish Conversations',
            'Story Listening',
            'Abstractions',
            'Story Bank',
          ],
        },
        {
          title: 'Understand my audience',
          cards: [
            'Audience Profile',
            'Circle Of Life',
            'Story-ish Conversations',
            'Big, Small, Inside, Outside',
          ],
        },
        {
          title: 'Get to the heart of the story',
          cards: [
            'Emotional Dashboard',
            'Movie Time',
            'Universal Stories',
            'Icebreaker Stories',
          ],
        },
        {
          title: 'Sell my ideas, products and services',
          cards: [
            'Simple Sales Stories',
            'Pitch Perfect',
            'Social Proof',
            'No Easy Way',
          ],
        },
        {
          title: 'Create relatable characters',
          cards: [
            'Hero & Guide',
            'What\u2019s My Motivation?',
            'Good & Evil',
            'Rags To Riches',
          ],
        },
        {
          title: 'Use stories to shape my brand',
          cards: [
            'Hero & Guide',
            'Social Proof',
            'Three Great Conflicts',
            'Rolls Royce Moment',
          ],
        },
        {
          title: 'Turn facts and data into stories',
          cards: [
            'Data Detectives',
            'Innovation Curve',
            'That\u2019s Funny',
            'Curious Tales',
          ],
        },
        {
          title: 'Get people to trust my judgement',
          cards: [
            'Trust Me, I\u2019m An Expert',
            'Audience Profile',
            'What\u2019s My Motivation?',
            'Data Detectives',
          ],
        },
        {
          title: 'Present my ideas clearly',
          cards: [
            'Three Is The Magic Number',
            'Five Ts',
            'Rolls Royce Moment',
            'Show & Tell',
          ],
        },
        {
          title: 'Give and receive feedback',
          cards: [
            'Thoughtful Failures',
            'Downfall',
            'Epic Fail',
            'Story Listening',
          ],
        },
        {
          title: 'Structure a longer talk',
          cards: [
            'Show & Tell',
            'Pride & Fall',
            'No Easy Way',
            'Happy Ever Afters',
          ],
        },
      ],
    },
    {
      deckLink: 'Write powerful copy',
      deckFocusPrompt: 'More specifically, I want to...',
      deckId: '12',
      focusOptions: [
        {
          title: 'Learn the foundation of great copy',
          cards: [
            'Persuasion Playbook',
            'Copywriting Commandments',
            'Communication Laws',
            '5 Inches, 5 Miles',
          ],
        },
        {
          title: 'Know my product',
          cards: [
            'Mystery Shop',
            'Factory Tour',
            'Origin Stories',
            'Benevolent Interrogation',
          ],
        },
        {
          title: 'Understand my audience',
          cards: [
            'Tension Mapping',
            'Not Your Average Survey',
            'Audience Insight Canvas',
            'Content Map',
          ],
        },
        {
          title: 'Learn from my audience',
          cards: [
            'Word Raider',
            'In The Jungle',
            'Finish This Sentence',
            'Funny You Should Say That',
          ],
        },
        {
          title: 'Tap into emotions',
          cards: [
            'Own A Problem',
            'Get Personal',
            'Paint A Picture',
            'Intrinsic Values',
          ],
        },
        {
          title: "Find my brand's voice",
          cards: [
            'Guess Who',
            'Four Dimensions',
            'Vital Voices',
            'Find The Edges',
          ],
        },
        {
          title: 'Make my words engaging',
          cards: [
            'Sense Storm',
            'Make It Musical',
            'Reverse Persona',
            'It’s Like...',
          ],
        },
        {
          title: 'Structure my copy',
          cards: [
            'Problem, Solution, Outcome',
            'Story DNA',
            'Get Presidential',
            'Copy Cake',
          ],
        },
        {
          title: 'Test audience reactions',
          cards: [
            'Coffee Shop Test',
            'Highlighter Test',
            'Recall Test',
            'Read It Aloud',
          ],
        },
        {
          title: 'Trim unnecessary words',
          cards: [
            '4, 3, 2, 1',
            'Be Ruthless',
            'Readability Rocks',
            'Problem, Solution, Outcome',
          ],
        },
        {
          title: 'Create memborable copy',
          cards: [
            'Naming Ninja',
            'Name Storm',
            'Syntax Tricks',
            'Speedwriting',
          ],
        },
        {
          title: 'Craft compelling headlines',
          cards: [
            'Headline Hustle',
            'Headline Hooks',
            'The Four ‘U’s',
            'Slippery Slide',
          ],
        },
      ],
    },
    {
      deckLink: 'Generate creative ideas',
      deckFocusPrompt: 'More specifically, I want to...',
      deckId: '4',
      focusOptions: [
        {
          title: 'Prepare my mind and body',
          cards: [
            'Zen Spiral',
            'Mind-Body Dissonance',
            'Thunks',
            'Mood Check-In',
          ],
        },
        {
          title: 'Warm up my creativity',
          cards: ['Invent an Animal', 'Haiku', 'Dream Sketch', 'Draw Your Job'],
        },
        {
          title: 'Be prepared',
          cards: [
            'Prep Like A Pro',
            'Invaluable Inputs',
            'Facilitation Tips',
            'Boss Mode',
          ],
        },
        {
          title: 'Clarify my challenge',
          cards: [
            'Fence The Playground',
            'Who, What, Where, Why',
            'Perform The Problem',
            'How Might We?',
          ],
        },
        {
          title: 'Learn from others',
          cards: [
            'Normstorming',
            'Fame Vs Shame',
            'What Would X Do?',
            'Analogous Inspiration',
          ],
        },
        {
          title: 'Reframe my challenge',
          cards: ['How Might We?', 'Observe/Infer', 'Make a Meme', 'Mash-Up'],
        },
        {
          title: 'Generate lots of different ideas',
          cards: [
            'Eight-Box Blitz',
            'Lotus Blossoms',
            'Worst Idea Race',
            '6-3-5',
          ],
        },
        {
          title: 'Organise my ideas',
          cards: ['Mind Map', 'Combinaboards', 'Big Beats', 'Back & Forth'],
        },
        {
          title: 'Push my ideas further',
          cards: [
            'Yes, And...',
            'ProvocaPrompts',
            'Human Truths',
            'Peak-End Rule',
          ],
        },
        {
          title: 'Strengthen my strongest ideas',
          cards: ['Storyboard', 'Mega Memo', 'Like/Not Like', 'T-Bar'],
        },
        {
          title: 'Get meaningful feedback',
          cards: [
            'Concerns, Confusions, Conflicts',
            'Like, Wish, Wonder',
            'Deep Impact',
            'Angels & Demons',
          ],
        },
        {
          title: 'Prioritise my ideas',
          cards: [
            'SICFAM',
            'PrioriTree',
            'Invest Your PipCoins',
            'Deep Impact',
          ],
        },
      ],
    },
  ],
};

export default SolveMyProblemContent;
