export const cardDecks = [
  {
    "id": 12,
    "title": "Copywriting Tactics",
    "description": "Ditch dull marketing.<br/>Craft copy that sells.",
    "imageUrl": "https://divergent-smoggy-farm.media.strapiapp.com/Deck_Copywriting_3x_ffa08fc462.png",
    "type": "full",
    "shopUrl": "https://pipdecks.com/products/copywriting-tactics",
    "pdfDownloadUrl": "https://divergent-smoggy-farm.media.strapiapp.com/Copywriting_Tactics_PDF_e4244e5bb6.pdf",
    "imagesArchiveDownloadUrl": "https://divergent-smoggy-farm.media.strapiapp.com/Copywriting_Tactics_Drag_and_drop_cards_e7199f77e8.zip"
  },
  {
    "id": 11,
    "title": "Negotiation Tactics",
    "description": "Ditch dead ends.<br/>Reach win-wins.",
    "imageUrl": "https://divergent-smoggy-farm.media.strapiapp.com/Deck_Negotiation_3x_ee642254a7.png",
    "type": "full",
    "shopUrl": "https://pipdecks.com/products/negotiation-tactics",
    "pdfDownloadUrl": "https://divergent-smoggy-farm.media.strapiapp.com/Negotiation_Tactics_354e048b02.pdf",
    "imagesArchiveDownloadUrl": "https://divergent-smoggy-farm.media.strapiapp.com/Negotiation_Tactics_Drag_and_drop_cards_39d9b1124d.zip"
  },
  {
    "id": 2,
    "title": "Storyteller Tactics",
    "description": "Ditch dull presentations.<br/>Tell great stories.",
    "imageUrl": "https://divergent-smoggy-farm.media.strapiapp.com/Deck_Storytelling_3x_06b60e1638.png",
    "type": "full",
    "shopUrl": "https://pipdecks.com/products/storyteller-tactics",
    "pdfDownloadUrl": "https://divergent-smoggy-farm.media.strapiapp.com/Storyteller_Tactics_PDF_076e5e8360.pdf",
    "imagesArchiveDownloadUrl": "https://divergent-smoggy-farm.media.strapiapp.com/Storyteller_Tactics_Drag_and_drop_cards_816f7a6327.zip"
  },
  {
    "id": 7,
    "title": "Brand Tactics",
    "description": "Ditch dull business.<br/>Create standout brands.",
    "imageUrl": "https://divergent-smoggy-farm.media.strapiapp.com/Deck_Brand_3x_97f1675c0e.png",
    "type": "full",
    "shopUrl": "https://pipdecks.com/products/brand-tactics",
    "pdfDownloadUrl": "https://divergent-smoggy-farm.media.strapiapp.com/Brand_Tactics_PDF_2c3897454a.pdf",
    "imagesArchiveDownloadUrl": "https://divergent-smoggy-farm.media.strapiapp.com/Brand_Tactics_Drag_and_drop_cards_79d03f77d6.zip"
  },
  {
    "id": 1,
    "title": "Workshop Tactics",
    "description": "Ditch dull meetings.<br/>Design better products.",
    "imageUrl": "https://divergent-smoggy-farm.media.strapiapp.com/Deck_Workshop_3x_350d9b84fe.png",
    "type": "full",
    "shopUrl": "https://pipdecks.com/products/workshop-tactics",
    "pdfDownloadUrl": "https://divergent-smoggy-farm.media.strapiapp.com/Workshop_20_Tactics_20_PDF_6acc5376cb.pdf",
    "imagesArchiveDownloadUrl": "https://divergent-smoggy-farm.media.strapiapp.com/Workshop_Tactics_Drag_and_drop_cards_d290bcc7ad.zip"
  },
  {
    "id": 4,
    "title": "Idea Tactics",
    "description": "Ditch creative block. <br> Unleash big ideas.",
    "imageUrl": "https://divergent-smoggy-farm.media.strapiapp.com/Idea_490c659754.png",
    "type": "full",
    "shopUrl": "https://pipdecks.com/products/idea-tactics",
    "pdfDownloadUrl": "https://divergent-smoggy-farm.media.strapiapp.com/Idea_Tactics_PDF_6e7c46e0e2.pdf",
    "imagesArchiveDownloadUrl": "https://divergent-smoggy-farm.media.strapiapp.com/Idea_Tactics_Drag_and_drop_cards_c10c1408c4.zip"
  },
  {
    "id": 8,
    "title": "Productivity Tactics",
    "description": "Ditch procrastination.<br/>Get stuff done.",
    "imageUrl": "https://divergent-smoggy-farm.media.strapiapp.com/Productivity_3x_41c220f5c0.png",
    "type": "full",
    "shopUrl": "https://pipdecks.com/products/productivity-tactics",
    "pdfDownloadUrl": "https://divergent-smoggy-farm.media.strapiapp.com/Productivity_Tactics_PDF_9491fee3dd.pdf",
    "imagesArchiveDownloadUrl": "https://divergent-smoggy-farm.media.strapiapp.com/Productivity_Tactics_Drag_and_drop_cards_fc26989cff.zip"
  },
  {
    "id": 5,
    "title": "Strategy Tactics",
    "description": "Ditch vague nonsense.<br/>Make clear decisions.",
    "imageUrl": "https://divergent-smoggy-farm.media.strapiapp.com/Deck_Strategy_3x_2919534712.png",
    "type": "full",
    "shopUrl": "https://pipdecks.com/products/strategy-tactics",
    "pdfDownloadUrl": "https://divergent-smoggy-farm.media.strapiapp.com/Strategy_Tactics_PDF_d1d01296a5.pdf",
    "imagesArchiveDownloadUrl": "https://divergent-smoggy-farm.media.strapiapp.com/Strategy_Tactics_Drag_and_drop_cards_13d2e4efb9.zip"
  },
  {
    "id": 3,
    "title": "Innovation Tactics",
    "description": "Ditch the guesswork.<br/>Make things people want.",
    "imageUrl": "https://divergent-smoggy-farm.media.strapiapp.com/Deck_Innovation_3x_0f04d416a7.png",
    "type": "full",
    "shopUrl": "https://pipdecks.com/products/innovation-tactics",
    "pdfDownloadUrl": "https://divergent-smoggy-farm.media.strapiapp.com/Innovation_Tactics_PDF_6028d39170.pdf",
    "imagesArchiveDownloadUrl": "https://divergent-smoggy-farm.media.strapiapp.com/Innovation_Tactics_Drag_and_drop_cards_a18aec2c28.zip"
  },
  {
    "id": 6,
    "title": "Team Tactics",
    "description": "Ditch chaotic mess.<br/>Design great teams.",
    "imageUrl": "https://divergent-smoggy-farm.media.strapiapp.com/Deck_Team_3x_bb8a881360.png",
    "type": "full",
    "shopUrl": "https://pipdecks.com/products/team-tactics",
    "pdfDownloadUrl": "https://divergent-smoggy-farm.media.strapiapp.com/Team_Tactics_PDF_0b14e2ad9d.pdf",
    "imagesArchiveDownloadUrl": "https://divergent-smoggy-farm.media.strapiapp.com/Team_Tactics_Drag_and_drop_cards_c93d7eaa89.zip"
  },
  {
    "id": 9,
    "title": "Retros",
    "description": "Ditch misalignment.<br/>Move forward as a team.",
    "imageUrl": "https://divergent-smoggy-farm.media.strapiapp.com/Deck_Retros_3x_d8083102f0.png",
    "type": "expansion",
    "shopUrl": "https://pipdecks.com/products/retros",
    "pdfDownloadUrl": "https://divergent-smoggy-farm.media.strapiapp.com/Retros_Expansion_Workshop_Tactics_PDF_041a0bcd81.pdf",
    "imagesArchiveDownloadUrl": "https://divergent-smoggy-farm.media.strapiapp.com/Retros_Expansion_Workshop_Tactics_Drag_and_drop_cards_febc133ce3.zip"
  },
  {
    "id": 10,
    "title": "Archetypes",
    "description": "Ditch dull stories.<br/>Create relatable characters.",
    "imageUrl": "https://divergent-smoggy-farm.media.strapiapp.com/Deck_Archetypes_3x_16c1d69b7b.png",
    "type": "expansion",
    "shopUrl": "https://pipdecks.com/products/archetypes",
    "pdfDownloadUrl": "https://divergent-smoggy-farm.media.strapiapp.com/Archetypes_Expansion_Storyteller_Tactics_PDF_18a33c8035.pdf",
    "imagesArchiveDownloadUrl": "https://divergent-smoggy-farm.media.strapiapp.com/Archetypes_Expansion_Storyteller_Tactics_Drag_and_drop_cards_c479883a9a.zip"
  }
];